import { useEffect, useRef, useState } from 'react';
import { getEnvFromURL } from 'utils/environment';
import WebRTCSession from 'webRTC';
import { LocalMedia, RemoteMedia } from 'webRTC/types';
import { getStats } from './internals';
import {
	DataChannelMessageType,
	DataChannels,
	GobeWebRTCSessionConfiguration,
	PeerConnectionEndReasonCode,
	PrimaryCameraState,
	RobotPrimaryCamera,
	RobotStatus,
	SessionState,
	SOFT_RETRIES,
	SOFT_RETRY_DELAY_MS,
} from './types';
import { arrayToKeyValueDict, localTracksLabelMap, remoteTracksMidsMap } from './utils';

/**
 * GoBeWebRTC factory.
 *
 * Returns a GoBe web rtc session.
 */
export default function useGoBeWebRTC(configuration: GobeWebRTCSessionConfiguration): {
	gobeWebRTC: WebRTCSession | undefined;
	pilotMedia: { [trackKey: string]: LocalMedia };
	robotMedia: { [trackKey: string]: RemoteMedia };
	dataChannels: { [label: string]: RTCDataChannel };
	robotStatus: RobotStatus | undefined;
	sessionState: SessionState;
	primaryCameraState: PrimaryCameraState;
	toggleZoom: () => void;
	togglePause: () => void;
	reportWebRTCEvent: (data: any) => void;
	start: () => void;
	end: (reason?: PeerConnectionEndReasonCode) => void;
} {
	const webRTCSessionConfiguration = useRef(configuration.webRTCSessionConfiguration).current;
	const [pilotMedia, setPilotMedia] = useState<{ [trackKey: string]: LocalMedia }>({});
	const [robotMedia, setRobotMedia] = useState<{ [trackKey: string]: RemoteMedia }>({});
	const [dataChannels, setDataChannels] = useState<{ [label: string]: RTCDataChannel }>({});
	const [robotStatus, setRobotStatus] = useState<RobotStatus | undefined>();
	const [gobeWebRTC, setGobeWebRTC] = useState<WebRTCSession>();
	const [sessionState, setSessionState] = useState<SessionState>('NotInitialized');
	const [softRetryIntervalId, setSoftRetryIntervalId] = useState<ReturnType<typeof setInterval>>();
	const [primaryCameraState, setPrimaryCameraState] = useState<PrimaryCameraState>({
		currentPrimaryCamera: RobotPrimaryCamera.WIDE_CAM,
		isChangingPrimaryCameraTo: null,
	});

	const clearSoftRetryInterval = () => {
		console.log('CLEARING SOFT RETRY INTERVAL');
		clearInterval(softRetryIntervalId!);
		setSoftRetryIntervalId(undefined);
	};
	const toggleZoom: () => void = () => {
		if (!gobeWebRTC) return;
		const isChangingPrimaryCameraTo =
			primaryCameraState?.currentPrimaryCamera === RobotPrimaryCamera.WIDE_CAM
				? RobotPrimaryCamera.ZOOM_CAM
				: RobotPrimaryCamera.WIDE_CAM;
		gobeWebRTC?.watchRTC.addEvent({
			type: 'global',
			name: 'switchingCamera',
			parameters: {
				toCameraType: isChangingPrimaryCameraTo,
				currentCameraType: primaryCameraState?.currentPrimaryCamera,
			},
		});
		dataChannels[DataChannels.CONTROL_DATACHANNEL].send(
			JSON.stringify({
				type: DataChannelMessageType.REQUEST_CAMERA_SWITCH,
			})
		);
		setPrimaryCameraState({
			...primaryCameraState!,
			isChangingPrimaryCameraTo,
		});
	};
	const togglePause: () => void = async () => {
		if (!gobeWebRTC) return;
		let streams: any = [];
		gobeWebRTC?.localMedia.forEach((localMedia) => {
			if (!streams.find((stream: any) => stream.id === localMedia.media?.stream.id))
				streams.push(localMedia.media?.stream);
		});
		streams.forEach((stream: MediaStream) =>
			stream.getTracks()?.forEach((track: MediaStreamTrack) => {
				track.enabled = !track.enabled;
			})
		);
		gobeWebRTC?.remoteMedia.forEach(
			(remoteMedia) => (remoteMedia.track.enabled = !remoteMedia.track.enabled)
		);
		const isPaused = sessionState === 'Paused';
		gobeWebRTC?.watchRTC.addEvent({
			type: 'global',
			name: isPaused ? 'sessionUnpaused' : 'sessionPaused',
			parameters: {},
		});
		dataChannels[DataChannels.CONTROL_DATACHANNEL].send(
			JSON.stringify({
				type: isPaused ? DataChannelMessageType.RESUME : DataChannelMessageType.PAUSE,
			})
		);
		setSessionState(isPaused ? 'InProgress' : 'Paused');
	};
	const reportWebRTCEvent: (data: any) => void = ({ type, name, parameters }) => {
		gobeWebRTC?.watchRTC.addEvent({
			type,
			name,
			parameters,
		});
	};
	const start: () => void = () => {
		setPilotMedia({});
		setRobotMedia({});
		setDataChannels({});
		setRobotStatus(configuration.robotStatus);
		const webRTCSession = new WebRTCSession({
			...webRTCSessionConfiguration,
			localMediaConstraints: {
				audio: {
					sampleSize: 16,
					sampleRate: 48000,
					channelCount: { min: 1, max: 2, ideal: 2 },
					echoCancellation: true,
					autoGainControl: false,
					noiseSuppression: false,
				} as unknown as MediaStreamConstraints['audio'],
				video: {
					facingMode: 'user',
					width: 640,
					height: 480,
					frameRate: { max: 20 },
				},
			},
			ondatachannel: [],
			dataChannels: [
				{
					label: DataChannels.NAV_DATACHANNEL,
					onmessage: (e) => {
						console.debug(
							'RECEIVED MESSAGE FROM DATACHANNEL',
							DataChannels.NAV_DATACHANNEL,
							e.data
						);
					},
				},
				{
					label: DataChannels.CONTROL_DATACHANNEL,
					onmessage: (e) => {
						try {
							const { type, data } = JSON.parse(e.data);
							if (!type) {
								console.debug('Received invalid message from control-datachannel', e);
								return;
							}

							console.log(
								'RECEIVED MESSAGE FROM DATACHANNEL control-datachannel',
								DataChannels.CONTROL_DATACHANNEL,
								e.data
							);

							switch (type) {
								case DataChannelMessageType.DID_SWITCH_CAMERA:
									if (!Object.values(RobotPrimaryCamera).includes(data.currentPrimaryCamera))
										console.debug('Received invalid message data from control-datachannel', e);
									setPrimaryCameraState({
										currentPrimaryCamera: data.currentPrimaryCamera,
										isChangingPrimaryCameraTo: null,
									});
									gobeWebRTC?.watchRTC.addEvent({
										type: 'global',
										name: 'switchCameraSuccess',
										parameters: {
											toCameraType: data.currentPrimaryCamera,
											currentCameraType: primaryCameraState.currentPrimaryCamera,
										},
									});
									break;
								case DataChannelMessageType.FAILED_TO_SWITCH_CAMERA:
									if (!Object.values(RobotPrimaryCamera).includes(data.currentPrimaryCamera))
										console.debug('Received invalid message data from control-datachannel', e);
									setPrimaryCameraState({
										currentPrimaryCamera: data.currentPrimaryCamera,
										isChangingPrimaryCameraTo: null,
									});
									gobeWebRTC?.watchRTC.addEvent({
										type: 'global',
										name: 'switchCameraError',
										parameters: {
											toCameraType: data.toCameraType,
											currentCameraType: primaryCameraState.currentPrimaryCamera,
											error: data.error,
										},
									});
									setPrimaryCameraState({
										...primaryCameraState,
										isChangingPrimaryCameraTo: null,
									});
									break;
							}
						} catch (error) {
							console.error('Cannot process status message received', error, 'Message', e);
						}
					},
				},
				{
					label: DataChannels.STATUS_DATACHANNEL,
					onmessage: (e) => {
						console.debug(
							'RECEIVED MESSAGE FROM DATACHANNEL',
							DataChannels.STATUS_DATACHANNEL,
							e.data
						);
						try {
							const { type, data } = JSON.parse(e.data);
							switch (type) {
								case DataChannelMessageType.ROBOT_STATUS:
									setRobotStatus(data);
									break;
							}
						} catch (error) {
							console.error('Cannot process status message received', error, 'Message', e);
						}
					},
				},
			],
			onLocalMediaError: (error) => {
				console.error('LOCAL MEDIA ERROR', error);
				gobeWebRTC?.close(error.message);
			},
			onSDPOfferExtensions: {
				gobeSwitching: async () => {
					// Roaming handling
					console.log('RECEIVED GOBE SWITCHING - ROAMING STARTED');
					webRTCSession?.iceRestart();
				},
			},
			onSDPAnswerExtensions: {
				remoteReadyForRetry: () => {
					// Robot is ready for retry
					console.log('RECEIVED READY FOR RETRY - RESTARTING SESSION');
					gobeWebRTC?.close();
					start();
				},
			},
			onOpenConnectionListeners: {
				connectionstatechange: (state) => {
					console.log('CONNECTION STATE CHANGED: ', state);
				},
				iceconnectionstatechange: (state) => {
					console.log('ICE CONNECTION STATE CHANGED: ', state);
					switch (state) {
						case 'connected':
							setSessionState('InProgress');
							break;
						case 'disconnected':
							setSessionState('SoftRetrying');
							break;
						case 'failed':
							setSessionState('Retrying');
							break;
					}
				},
				icegatheringstatechange: (state) => {
					console.log('ICE GATHERING STATE CHANGED: ', state);
				},
				signalingstatechange: (state) => {
					console.log('SIGNALING STATE CHANGED: ', state);
				},
				negotiationneeded: () => {
					console.log('NEGOTIATION NEEDED');
				},
				icecandidateerror: (ev) => {
					console.log('ICE CANDIDATE ERROR: ', ev);
				},
			},
			watchRTC: {
				rtcApiKey: '2610b825-e4f2-4ab0-8b27-44820805465a',
				rtcRoomId: webRTCSessionConfiguration.id,
				rtcPeerId: webRTCSessionConfiguration.clientId,
				keys: {
					pilotId: webRTCSessionConfiguration.pilotId,
					robotId: webRTCSessionConfiguration.clientId,
					env: getEnvFromURL(),
				},
			},
		});

		webRTCSession.setLocalMedia = (value: LocalMedia[]) => {
			webRTCSession.localMedia = value;
			// Map local media to pilot media
			setPilotMedia(
				value
					? arrayToKeyValueDict(
							value,
							(localMedia) => localMedia?.media?.label!,
							localTracksLabelMap
					  )
					: {}
			);
		};
		webRTCSession.setRemoteMedia = (value: RemoteMedia[]) => {
			webRTCSession.remoteMedia = value;
			const newRobotMedia = arrayToKeyValueDict(
				value,
				(media) => media?.transceiver?.mid!,
				remoteTracksMidsMap
			);
			// Map remote media to robot media
			setRobotMedia(value ? newRobotMedia : {});
			Object.entries(newRobotMedia).forEach(([label, media]) => {
				webRTCSession.watchRTC.mapStream(media.streams[0].id!, label);
			});
		};
		webRTCSession.setDataChannels = (value: RTCDataChannel[]) => {
			webRTCSession.dataChannels = value;
			// Map data channels to key-labelled data channels
			setDataChannels(value ? arrayToKeyValueDict(value, (channel) => channel?.label!) : {});
		};

		(window as any).getWebRTCStats = () => getStats(webRTCSession.peerConnection);
		(window as any).iceRestart = () => webRTCSession.iceRestart();
		(window as any).webRTCSession = webRTCSession;
		(window as any).setSessionState = (state: SessionState) => setSessionState(state);

		setGobeWebRTC(webRTCSession);
		setSoftRetryIntervalId(undefined);
		setPrimaryCameraState({
			currentPrimaryCamera: RobotPrimaryCamera.WIDE_CAM,
			isChangingPrimaryCameraTo: null,
		});
		webRTCSession?.open();
	};
	const restart: () => void = () => {
		gobeWebRTC?.signalingClient.sendSDPOffer({
			sdp: '',
			type: 'readyForRetry',
			toJSON: function () {
				return { sdp: this.sdp, type: this.type };
			},
		} as any);
		gobeWebRTC?.watchRTC.addEvent({
			type: 'global',
			name: 'hardRetry',
			parameters: {},
		});
	};
	const end: (reason?: PeerConnectionEndReasonCode) => void = (reason = 'LOCAL_HANGUP') => {
		if (!gobeWebRTC) return;
		try {
			dataChannels[DataChannels.CONTROL_DATACHANNEL].send(
				JSON.stringify({
					type: DataChannelMessageType.HANG_UP,
					data: reason,
				})
			);
		} catch (e) {
			console.error(e);
		}
		setTimeout(() => {
			gobeWebRTC?.close(reason);
			window.opener.postMessage({ refreshPage: true }, document.referrer);
			window.close();
		}, 500);
	};
	// Close when unmounting
	useEffect(() => {
		return () => {
			if (gobeWebRTC) gobeWebRTC?.close();
		};
	}, [gobeWebRTC]);

	useEffect(() => {
		switch (sessionState) {
			case 'InProgress':
				clearSoftRetryInterval();
				break;
			case 'SoftRetrying':
				if (!softRetryIntervalId) {
					let retries = SOFT_RETRIES;
					console.log('STARTED SOFT RETRY', retries);
					setSoftRetryIntervalId(
						setInterval(() => {
							console.debug('Soft retrying', retries);
							if (retries === 0) {
								setSessionState('Retrying');
							} else if (sessionState === 'SoftRetrying') gobeWebRTC?.iceRestart();
							retries--;
						}, SOFT_RETRY_DELAY_MS)
					);
				}
				break;
			case 'Retrying':
				console.log('STARTED HARD RETRY');
				clearSoftRetryInterval();
				restart();
				break;
		}
		return () => {
			clearTimeout(softRetryIntervalId!);
			setSoftRetryIntervalId(undefined);
		};
	}, [sessionState]);

	// TODO: Remove for release
	(window as any).getSessionState = () => sessionState;
	(window as any).start = start;
	(window as any).end = end;

	return {
		gobeWebRTC,
		pilotMedia,
		robotMedia,
		dataChannels,
		robotStatus,
		sessionState,
		primaryCameraState,
		toggleZoom,
		togglePause,
		reportWebRTCEvent,
		start,
		end,
	};
}
