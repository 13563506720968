/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useMemo, useState } from 'react';
import './index.scss';
import Battery from '../battery';

import lowWiFiSignalIcon from 'images/wifi-signal-low.svg';
import mediumWiFiSignalIcon from 'images/wifi-signal-medium.svg';
import badWiFiSignalIcon from 'images/wifi-signal-bad.svg';
import highWifiSignalIcon from 'images/wifi.svg';
import lowWiFiSignalIconHl from 'images/wifi-signal-low-hl.svg';
import mediumWiFiSignalIconHl from 'images/wifi-signal-medium-hl.svg';
import badWiFiSignalIconHl from 'images/wifi-signal-bad-hl.svg';
import highWifiSignalIconHl from 'images/wifi-hl.svg';

import removeIcon from 'images/remove.svg';
import goBeHeadIcon from 'images/GoBe-head.svg';
import { RobotStatus, SessionState } from 'GoBeWebRTC/types';

type ComponentProps = {
	robotStatus: RobotStatus;
	isLocalVideoExpanded: boolean;
	onToggleLocalVideoExpansion: () => void;
	sessionState: SessionState;
};
export type Battery = {
	level: string;
	charging: boolean;
};
export type Network = {
	quality: number;
	ssid: string | null;
};

const LocalVideoHeader: React.FC<ComponentProps> = ({
	robotStatus,
	isLocalVideoExpanded,
	onToggleLocalVideoExpansion,
	sessionState,
}) => {
	const isSessionSoftRetrying: boolean = sessionState === 'SoftRetrying';
	const [wifiIcon, setWifiIcon] = useState<string>(highWifiSignalIcon);
	const [wifiIconLoopId, setWifiIconLoopId] = useState<ReturnType<typeof setInterval>>();
	const [showWifiTooltip, setShowWifiTooltip] = useState<boolean>(false);

	const interfaceId = useMemo(() => {
		setShowWifiTooltip(true);
		setTimeout(() => {
			setShowWifiTooltip(false);
		}, 2000);
		return robotStatus?.interface?.interface_id;
	}, [robotStatus?.interface?.interface_id]);

	useEffect(() => {
		const wifiIcons = [
			badWiFiSignalIcon,
			lowWiFiSignalIcon,
			mediumWiFiSignalIcon,
			highWifiSignalIcon,
		];
		if (isSessionSoftRetrying) {
			if (!wifiIconLoopId) {
				let index: number = 0;
				clearInterval(wifiIconLoopId!);
				setWifiIconLoopId(
					setInterval(() => {
						setWifiIcon(wifiIcons[index]);
						index = (index + 1) % wifiIcons.length;
					}, 300)
				);
			}
		} else {
			clearInterval(wifiIconLoopId!);
			setWifiIconLoopId(undefined);
		}
		return () => {
			clearInterval(wifiIconLoopId!);
			setWifiIconLoopId(undefined);
		};
	}, [isSessionSoftRetrying]);

	useEffect(() => {
		let icon = highWifiSignalIcon;
		if (robotStatus.network) {
			const quality = robotStatus.network.quality;
			switch (true) {
				case quality >= 80:
					icon = showWifiTooltip ? highWifiSignalIconHl : highWifiSignalIcon;
					break;
				case quality < 80 && quality >= 66:
					icon = showWifiTooltip ? mediumWiFiSignalIconHl : mediumWiFiSignalIcon;
					break;

				case quality < 66 && quality >= 56:
					icon = showWifiTooltip ? lowWiFiSignalIconHl : lowWiFiSignalIcon;
					break;

				case quality < 56:
					icon = showWifiTooltip ? badWiFiSignalIconHl : badWiFiSignalIcon;
					break;
			}
		}
		setWifiIcon(icon);
	}, [robotStatus.network, showWifiTooltip]);

	return (
		<div
			className={
				isLocalVideoExpanded
					? ' wifiBatteryContainer'
					: 'miniWifiBatteryContainer wifiBatteryContainer'
			}
		>
			<img
				onClick={onToggleLocalVideoExpansion}
				className="removeIconWrapper"
				src={isLocalVideoExpanded ? removeIcon : goBeHeadIcon}
			/>
			<div
				className="wifiIconWrapper"
				onMouseEnter={() => setShowWifiTooltip(true)}
				onMouseLeave={() => setShowWifiTooltip(false)}
			>
				<div className="wifiIcon">
					{showWifiTooltip ? (
						<div className="wifiTooltip">
							<div>
								{isSessionSoftRetrying
									? 'Reconnecting'
									: `WiFi${interfaceId ? `-${interfaceId}` : ''} Connected`}
							</div>
							<span className="wifiTooltipArrow" />
						</div>
					) : null}
					<img className="wifiIconImg" src={wifiIcon} />
					{!isSessionSoftRetrying ? <div className="wifiIconId">{interfaceId}</div> : null}
				</div>
			</div>
			<div className="batteryIconContainer">
				<Battery
					batteryPercent={robotStatus.battery.level}
					charging={robotStatus.battery.charging}
				/>
			</div>
		</div>
	);
};

export default React.memo(LocalVideoHeader);
