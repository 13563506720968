import { WebRTCSessionConfiguration } from 'webRTC/types';

export type IceTransportPolicy = 'all' | 'host' | 'reflex' | 'relay';

export type LocalTrackLabel = PilotPrimaryCamera.LOCAL;

export type RemoteTrackKey =
	| RobotPrimaryCamera.WIDE_CAM
	| RobotPrimaryCamera.ZOOM_CAM
	| RobotNavCamera.NAV_CAM
	| 'audio';

export enum PilotPrimaryCamera {
	LOCAL = 'local',
}

export enum RobotPrimaryCamera {
	WIDE_CAM = 'wide_cam',
	ZOOM_CAM = 'zoom_cam',
}

export enum RobotNavCamera {
	NAV_CAM = 'nav_cam',
}

export enum DataChannels {
	NAV_DATACHANNEL = 'nav-datachannel',
	CONTROL_DATACHANNEL = 'control-datachannel',
	STATUS_DATACHANNEL = 'status-datachannel',
}

/** Session Info that is generate from Roster page */
export type LocalSessionInfo = {
	awsClientConfiguration: AWSClientConfiguration;
	response: 'OK' | 'NOT_ALLOWED_BUSY' | 'NOT_ALLOWED_ERROR' | 'NOT_ALLOWED_NOT_READY';
	uuid: string;
	pilotId: string;
	robot: { id: string; name: string; serialNumber: string };
	pilot: { id: string; name: string; avatar?: string | null };
	devices?: {
		camera?: { name?: string };
		microphone?: { name?: string; microphoneLevel?: number };
		speaker?: { name?: string; speakerLevel?: number };
	};
	robotStatus?: RobotStatus;
	capabilities: Capabilities;
};

/** Gobe Web RTC session configuration */
export type GobeWebRTCSessionConfiguration = {
	webRTCSessionConfiguration: WebRTCSessionConfiguration;
	robotStatus: RobotStatus;
};

export type RobotStatus = { battery: Battery; network: Network; interface?: Interface };

export type Capabilities = {
	super_zoom_1: boolean;
	mouse_control_with_joystick: boolean;
	mouse_control_with_slider: boolean;
	wide_camera_rotation: 0 | 90 | 180 | 270;
	zoom_camera_rotation: 0 | 90 | 180 | 270;
	nav_camera_rotation: 0 | 90 | 180 | 270;
	wide_camera_crop: { top: number; right: number; bottom: number; left: number };
	autoParkEnabled?: boolean;
};

export type Battery = {
	level: string;
	charging: boolean;
};

export type Network = {
	id: number;
	quality: number;
	ssid: string | null;
};

export type Interface = {
	interface_id: number;
	interface_name: string;
	interface_type: string;
};

export type AWSClientConfiguration = {
	accessKeyId: string;
	secretAccessKey: string;
	iceTransportPolicy: IceTransportPolicy;
	region: string;
	id: string;
};

export type PeerConnectionEndReasonCode =
	| 'PEER_HANGUP'
	| 'LOCAL_HANGUP'
	| 'CLEANUP'
	| 'FAILED_STATE_TIMED_OUT'
	| 'PAUSED_STATE_TIMED_OUT'
	| 'RETRY_TIMEOUT'
	| 'RETRY_FAILED'
	| 'ERROR';

/** Representation of the state of the session. */
export type SessionState =
	| 'NotInitialized'
	| 'InProgress'
	| 'Paused'
	| 'SoftRetrying'
	| 'Retrying'
	| 'Ended';

export interface PrimaryCameraState {
	currentPrimaryCamera: RobotPrimaryCamera;
	isChangingPrimaryCameraTo: RobotPrimaryCamera | null;
}

export enum DataChannelMessageType {
	ROBOT_STATUS = 'robot_status',
	HANG_UP = 'hang_up',
	REQUEST_CAMERA_SWITCH = 'request_camera_switch',
	DID_SWITCH_CAMERA = 'did_switch_camera',
	FAILED_TO_SWITCH_CAMERA = 'failed_to_switch_camera',
	PAUSE = 'pause',
	RESUME = 'resume',
}

export const SOFT_RETRIES = 0;

export const SOFT_RETRY_DELAY_MS = 25000;
