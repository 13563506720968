import { useEffect, useLayoutEffect, useRef } from 'react';

/** Period (in milliseconds) for sending navigation commands to remote peer */
const SEND_COMMANDS_PERIOD_MS = 100;

/** If no new DOCK CONTINUE command is received for this long, we time out and stop looping CONTINUE command */
const DOCK_CONTINUE_COMMAND_MAX_DELAY = SEND_COMMANDS_PERIOD_MS * 10;

/** Period to ignore disruptive messages */
const IGNORE_MESSAGES_ON_SUCCESS_DELAY = 5000;
const IGNORE_MESSAGES_ON_FAILED_DELAY = 5000;

const AutoDockingControllerStages = ['STARTING', 'DETECTING', 'DOCKING', 'STOPPING'] as const;
const StatesOfStage = ['STARTED', 'SUCCESS', 'IN_PROGRESS', 'FAILED', 'RECOVERY'] as const;
/** The status of the docking controller is: at a certain stage, and the state within that stage */
export type AutoDockingStatus = {
	stage: typeof AutoDockingControllerStages[number];
	state: typeof StatesOfStage[number];
	customData?: any;
	timestamp?: number;
};

class _AutoDockingController {
	private loopId: ReturnType<typeof setInterval> | undefined;
	private lastReceivedCONTINUECommandTime = Number.MIN_SAFE_INTEGER;
	private lastSentCONTINUECommandTime = Number.MIN_SAFE_INTEGER;

	private dataChannel: RTCDataChannel | undefined;
	public onDataChannel(dataChannel: RTCDataChannel) {
		this.dataChannel = dataChannel;
		// bind the message event handler earlier, so that we don't miss any messages
		this.dataChannel?.addEventListener('message', this.onDockingMessageFromRemotePeer);
	}

	private eventTarget = new EventTarget();
	private _status: AutoDockingStatus = { stage: 'DETECTING', state: 'FAILED' };
	private _auto: boolean = false;

	public get status() {
		return this._status;
	}

	private onStatusChanged = (newStatus: AutoDockingStatus) => {
		const prevStatus = { ...this.status };
		this._status = { ...newStatus, timestamp: Date.now() };
		this.eventTarget.dispatchEvent(
			new CustomEvent('status-changed', { detail: { prevStatus, status: this.status } })
		);
	};

	public start = (auto = false) => {
		this._auto = auto;

		// dont want the remaining logic below to be executed more than once in a row, NEVER!🙅🏾‍♂️
		if (this.status.stage !== 'DETECTING') {
			console.debug('abort AutoDockingController.start() -> not detecting', this.status);
			return;
		} else if (this.status.state === 'FAILED') {
			this.onStatusChanged({ ...this.status, customData: 'FAILED' });
			return;
		}

		this.onStatusChanged({ stage: 'STARTING', state: 'IN_PROGRESS' });

		try {
			this.dataChannel!.send(`DOCK START ${performance.now()}`);
			this.onStatusChanged({ stage: 'STARTING', state: 'SUCCESS' });

			// reset control variables
			this.lastReceivedCONTINUECommandTime = performance.now();
			this.lastSentCONTINUECommandTime = performance.now();

			this.startDockCONTINUELoop();
		} catch (error) {
			this.onStatusChanged({ stage: 'STARTING', state: 'FAILED' });
			console.error('Failed to send DOCK START', error);
			this.stop();
		}
	};

	public stop = () => {
		if (!['STARTING', 'DOCKING'].includes(this.status.stage)) {
			console.debug('abort AutoDockingController.stop() -> not in starting or docking stage');
			this.stopDockCONTINUELoop();
			return;
		}

		const hasDockingCompletedSuccessfully =
			this.status.stage === 'DOCKING' && this.status.state === 'SUCCESS';
		this.stopDockCONTINUELoop();
		try {
			if (!hasDockingCompletedSuccessfully) {
				this.dataChannel?.send(`DOCK STOP ${performance.now()}`);
			}
		} catch (error) {
			console.error('Failed to send DOCK STOP', error);
		}
	};

	public reset = () => {
		if (!['STARTING', 'DOCKING'].includes(this.status.stage)) {
			console.debug('abort AutoDockingController.stop() -> not in starting or docking stage');
			return;
		}

		this.onStatusChanged({ stage: 'STOPPING', state: 'IN_PROGRESS' });
		this.onStatusChanged({ stage: 'STOPPING', state: 'SUCCESS' });
		this.onStatusChanged({ stage: 'DETECTING', state: 'FAILED' });
	};

	private loopCONTINUECommand = () => {
		let isAllowedToSendCONTINUE =
			(this.status.stage === 'STARTING' && this.status.state === 'SUCCESS') ||
			(this.status.stage === 'DETECTING' && this.status.state !== 'FAILED') ||
			(this.status.stage === 'DOCKING' && this.status.state === 'IN_PROGRESS') ||
			(this.status.stage === 'DOCKING' && this.status.state === 'STARTED') ||
			(this.status.stage === 'DOCKING' && this.status.state === 'RECOVERY') ||
			(this.status.stage === 'DOCKING' && this.status.state === 'FAILED');
		if (!isAllowedToSendCONTINUE) {
			console.debug('abort AutoDockingController.loop() -> disabled', this.status);
			return;
		}

		if (
			performance.now() - this.lastReceivedCONTINUECommandTime >
			DOCK_CONTINUE_COMMAND_MAX_DELAY
		) {
			console.debug(`abort AutoDockingController.loop() -> no-new-commands`);
			this.stop();
			return;
		}

		// if we have not been able to send a command in a while now, abort
		if (performance.now() - this.lastSentCONTINUECommandTime > DOCK_CONTINUE_COMMAND_MAX_DELAY) {
			console.debug(`abort AutoDockingController.loop() -> unable-to-send-CONTINUE`);
			this.stop();
			return;
		}

		if (this.dataChannel?.readyState !== 'open') {
			console.debug(
				`abort AutoDockingController.loop() -> datachannel.readyState '${this.dataChannel?.readyState}'`
			);
			return;
		}

		const autoDockingMessage = `DOCK CONTINUE ${performance.now().toFixed(3)}`;

		try {
			// yay! - now we can send the auto-docking-command to the remote peer
			this.dataChannel.send(autoDockingMessage);
			this.lastSentCONTINUECommandTime = performance.now();
			console.debug('AutoDockingController.loop()', autoDockingMessage);
		} catch (error) {
			// Sorry, nope. Cant send it. 😢
			console.error('failed AutoDockingController.loop() -> error', error);
		}
	};

	/** Idempotent */
	private startDockCONTINUELoop = () => {
		if (!['STARTING', 'DOCKING'].includes(this.status.stage)) {
			console.debug('abort AutoDockingController.startDockCONTINUELoop()', this.status);
			return;
		}
		// IMPORTANT: This check ensures that we never have multiple `intervals` running at the same time!
		if (this.loopId === undefined) {
			this.loopId = setInterval(this.loopCONTINUECommand, SEND_COMMANDS_PERIOD_MS);
		}
	};

	/** Idempotent */
	private stopDockCONTINUELoop = () => {
		if (this.loopId !== undefined) {
			clearInterval(this.loopId);
			this.loopId = undefined;
		}
	};

	private onDockingMessageFromRemotePeer = (e: MessageEvent) => {
		if (e.data === undefined) return;

		// Parse message
		const [_msgCategory, _stage, _state, _customData] = e.data
			.toString()
			.replace('b', '')
			.split(' ');
		const msgCategory = _msgCategory.replace("'", '');
		let stage = _stage.replace("'", '');
		let state = _state.replace("'", '');
		let customData = _customData?.replace("'", '');

		// Handle non dock commands
		if (msgCategory !== 'DOCK') {
			console.error('Invalid message format', e.data.toString(), [msgCategory, stage, state]);
			return; //only handle the messages pertaining to docking
		}

		// Discard detecting messages if in STARTING stage
		if (stage === 'DETECTING' && this.status.stage === 'STARTING') {
			console.warn('Discarding DETECTING message while in STARTING stage', {
				stage,
				state,
			});
			return;
		}

		// Replace by DOCKING FAILED when receiving COMMAND FAIL
		if (stage === 'DOCKING' && state === 'BLOCKED') {
			stage = 'DOCKING';
			state = 'FAILED';
			customData = 'BLOCKED';
		}

		// Replace STOPPED BY USER with DETECTING FAILED
		if (
			(stage === 'COMMAND' && state === 'FAIL' && this.status.stage !== 'DETECTING') ||
			stage === 'STOPPED'
		) {
			stage = 'DOCKING';
			state = 'FAILED';
			customData = 'STOPPED';
		}

		// Handle unknown stages
		if (!AutoDockingControllerStages.includes(stage)) {
			console.warn('Got invalid docking message', { stage, state });
			return;
		}

		// Handle receiving same stage and state
		if (
			this.status.stage === stage &&
			this.status.state === state &&
			this.status.customData === customData
		) {
			console.warn('Received same stage and state', { stage, state });
			return;
		} else {
			// Handle disruptive updates
			if (
				this.status.timestamp &&
				((this.status.stage === 'DOCKING' &&
					this.status.state === 'SUCCESS' &&
					this.status.timestamp + IGNORE_MESSAGES_ON_SUCCESS_DELAY > Date.now()) ||
					(this.status.state === 'FAILED' &&
						(this.status.stage === 'DOCKING' ||
							(this.status.stage === 'DETECTING' && this.status.customData)) &&
						this.status.timestamp + IGNORE_MESSAGES_ON_FAILED_DELAY > Date.now()))
			) {
				console.warn('Received disruptive message While still showing message', this.status, {
					stage,
					state,
				});
				return;
			}
			// Handle recovery
			if (
				this.status.stage === 'DOCKING' &&
				stage === 'DOCKING' &&
				((this.status.state === 'IN_PROGRESS' && state === 'ATTEMPT') ||
					(this.status.state === 'RECOVERY' && state === 'IN_PROGRESS') ||
					(this.status.state === 'RECOVERY' && state === 'ATTEMPT'))
			) {
				if (this._auto) state = 'RECOVERY';
				else {
					stage = 'DOCKING';
					state = 'FAILED';
					customData = 'FAILED';
				}
			}
		}

		if (state === 'RECOVERY' && !customData) customData = this.status.customData;

		this.onStatusChanged({
			stage: stage as unknown as AutoDockingStatus['stage'],
			state: state as unknown as AutoDockingStatus['state'],
			customData,
		});
	};

	public onDockCONTINUE = () => {
		if (!['STARTING', 'DOCKING'].includes(this.status.stage)) {
			console.debug('abort AutoDockingController.onNavCommand()', this.status);
			return;
		}

		this.lastReceivedCONTINUECommandTime = performance.now();
		this.startDockCONTINUELoop();
	};

	public addEventListener = (event: 'status-changed', listener: (...args: any[]) => void) => {
		this.eventTarget.addEventListener(event, listener);
	};

	public removeEventListener = (event: 'status-changed', listener: (...args: any[]) => void) => {
		this.eventTarget.removeEventListener(event, listener);
	};
}

export type AutoDockingController = Pick<
	_AutoDockingController,
	// expose only a subset of public methods of _AutoDockingController, outside this module
	| 'onDockCONTINUE'
	| 'start'
	| 'stop'
	| 'addEventListener'
	| 'removeEventListener'
	| 'status'
	| 'reset'
>;

export default function useAutoDockingController(props: {
	isPeerConnectionPaused: boolean;
	datachannel?: RTCDataChannel;
	/** Pass in True, if the video can be seen by the user, and there are no overlays, modals etc, obscuring the view */
	isVideoVisible: boolean;
	/** Penalty to be applied to speed  */
	penalty?: number;
}) {
	const { isPeerConnectionPaused, datachannel, penalty, isVideoVisible } = props;
	// a ref, because we dont ever change the created instance
	const autoDockingController = useRef(new _AutoDockingController());

	useLayoutEffect(() => {
		if (datachannel) autoDockingController.current.onDataChannel(datachannel);
	}, [datachannel]);

	useLayoutEffect(() => {
		const shouldDisableAutoDocking = isPeerConnectionPaused || !isVideoVisible;
		if (shouldDisableAutoDocking) {
			autoDockingController.current.stop();
		}
	}, [isPeerConnectionPaused, isVideoVisible]);

	useEffect(() => {
		//
	}, [penalty]);

	// Deactivate the AutoDockingController when the implementing component
	// 	is about to be unmounted.
	useLayoutEffect(() => {
		console.debug(`useAutoDockingController -> mounted`);
		const controller = autoDockingController.current;
		return () => {
			console.debug(`useAutoDockingController -> unmounted`);
			controller.stop(); // this is a no-op if it has been terminated already
		};
	}, []);

	return autoDockingController.current as AutoDockingController;
}
