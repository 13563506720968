import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './index.scss';
import useDimensions from 'react-cool-dimensions';
import { Rnd } from 'react-rnd';
import { CameraConfig } from 'types';
import { SessionState } from 'GoBeWebRTC/types';
import { sessionSoftRetryingFilter } from 'utils/styling';

type MediaStylesOptions = {
	containerWidth: number;
	containerHeight: number;
	crop: { top: number; right: number; bottom: number; left: number };
	cameraRotationDegree: 0 | 90 | 180 | 270;
	videoFrameWidth: number;
	videoFrameHeight: number;
};

const VIDEO_FRAME_WIDTH = 1280;
const VIDEO_FRAME_HEIGHT = 720;

const UNCROPPED_VIDEO_FRAME_CONFIG = { x1: 0, x2: VIDEO_FRAME_WIDTH - 100 };

/** Fraction of the Window's height */
const [MAX_HEIGHT_RATIO, MIN_HEIGHT_RATIO] = [0.75, 0.4];

const computeMediaStyles = ({
	containerWidth,
	containerHeight,
	crop,
	cameraRotationDegree,
	videoFrameWidth,
	videoFrameHeight,
}: MediaStylesOptions) => {
	let [x1, x2] = [
		Math.min(VIDEO_FRAME_WIDTH, Math.max(0, crop.left)),
		VIDEO_FRAME_WIDTH - Math.min(VIDEO_FRAME_WIDTH, Math.max(0, crop.right)),
	];

	if (crop.left + crop.right >= VIDEO_FRAME_WIDTH) {
		[x1, x2] = [0, 0];
	}

	const videoCroppingBoxWidth = x2 - x1;
	const videoCroppingBoxHeight = videoFrameHeight;

	let scaleFactor =
		containerWidth < containerHeight
			? containerWidth / videoCroppingBoxWidth
			: containerHeight / videoCroppingBoxHeight;
	scaleFactor = Number.isFinite(scaleFactor) ? scaleFactor : 1;

	const videoTranslateX = x1 * scaleFactor;
	const videoTranslateY = ((videoFrameHeight - videoCroppingBoxHeight) / 2) * scaleFactor;

	/** CSS style of the video element itself */
	const videoStyle: React.CSSProperties = {
		transform: `rotate(${cameraRotationDegree}deg) translateY(${videoTranslateY}px) translateX(${videoTranslateX}px)`,
		transformOrigin: 'center',
	};

	/** CSS style of the div (or other box element) used to crop (hide parts of) the video element. */
	const videoCroppingBoxStyle: React.CSSProperties = {
		width: videoCroppingBoxWidth * scaleFactor,
		height: videoCroppingBoxHeight * scaleFactor,
	};

	return Object.freeze({ videoStyle, videoCroppingBoxStyle });
};

type Dimension = {
	height: number;
	minHeight?: number | undefined;
	maxHeight?: number | undefined;
};
type DynamicMediaProps = {
	className?: string;
	drivingMode: boolean;
	fullScreenStatus: boolean;
	navCameraHeight: number;
	videoRef: any;
	resizeNavCamera: (value: number) => void;
	cameraConfig: CameraConfig;
	sessionState: SessionState;
} & React.VideoHTMLAttributes<HTMLVideoElement>;

function DynamicMedia({
	// className,
	videoRef,
	drivingMode: isInDrivingMode,
	resizeNavCamera,
	cameraConfig,
	fullScreenStatus: isInFullscreenMode,
	navCameraHeight,
	sessionState,
	...mediaComponentProps
}: DynamicMediaProps) {
	const isSessionSoftRetrying = sessionState === 'SoftRetrying';
	const {
		observe,
		width: observedResizeContainerWidth,
		// height: observedResizeContainerHeight,
	} = useDimensions<HTMLDivElement>();

	const [resizeContainerDimensions, _setResizeContainerDimensions] = useState<Dimension>({
		height: window.innerHeight - (isInDrivingMode ? navCameraHeight : 0),
		minHeight: isInDrivingMode ? MIN_HEIGHT_RATIO * window.innerHeight : undefined,
		maxHeight: isInDrivingMode ? MAX_HEIGHT_RATIO * window.innerHeight : undefined,
	});

	const heightRatio = useRef(resizeContainerDimensions.height / window.innerHeight);

	const { videoCroppingBoxStyle, videoStyle } = useMemo(() => {
		let { videoCroppingBoxStyle, videoStyle } = computeMediaStyles({
			cameraRotationDegree: cameraConfig.rotationDegrees,
			containerWidth: observedResizeContainerWidth,
			containerHeight: resizeContainerDimensions.height,
			crop: {
				top: cameraConfig.crop.top,
				right: cameraConfig.crop.right,
				bottom: cameraConfig.crop.bottom,
				left: cameraConfig.crop.left,
			},
			videoFrameHeight: VIDEO_FRAME_HEIGHT,
			videoFrameWidth: VIDEO_FRAME_WIDTH,
		});
		videoStyle = {
			...videoStyle,
			...sessionSoftRetryingFilter(isSessionSoftRetrying),
		};
		return { videoCroppingBoxStyle, videoStyle };
	}, [
		observedResizeContainerWidth,
		cameraConfig.rotationDegrees,
		cameraConfig.crop.top,
		cameraConfig.crop.right,
		cameraConfig.crop.bottom,
		cameraConfig.crop.left,
		resizeContainerDimensions.height,
		isSessionSoftRetrying,
	]);

	const updateComponentDimensions = useCallback(
		(dimension: Dimension) => {
			const newHeightRatio = Math.min(1, dimension.height / window.innerHeight);

			if (isInDrivingMode) {
				if (newHeightRatio >= MIN_HEIGHT_RATIO && newHeightRatio <= MAX_HEIGHT_RATIO) {
					heightRatio.current = newHeightRatio;
					_setResizeContainerDimensions((state) => ({
						...state,
						...dimension,
					}));
				}
			} else {
				// LOGIC:
				_setResizeContainerDimensions((state) => ({ ...state, ...dimension }));
				//
				// 1. In meeting mode (the opposite of driving-mode), we dont want to track the height ratio.
				//    We maintain the last known ratio. When the user goes back into driving-mode, we will re-use this last-known ratio
				//      to determine the height of the primary-camera
			}

			resizeNavCamera((1 - heightRatio.current) * window.innerHeight);
		},
		[isInDrivingMode, resizeNavCamera]
	);

	// Update the component's dimensions when we toggle driving mode, or toggle fullscreen or resize the window.
	useEffect(() => {
		const recomputeDimensions = () => {
			if (isInDrivingMode) {
				const height = window.innerHeight * heightRatio.current;

				updateComponentDimensions({
					height,
					minHeight: MIN_HEIGHT_RATIO * window.innerHeight,
					maxHeight: MAX_HEIGHT_RATIO * window.innerHeight,
				});
			} else {
				updateComponentDimensions({
					height: window.innerHeight,
					minHeight: undefined,
					maxHeight: undefined,
				});
			}
		};

		recomputeDimensions();

		window.addEventListener('resize', recomputeDimensions);
		return () => {
			window.removeEventListener('resize', recomputeDimensions);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		isInDrivingMode,
		isInFullscreenMode,
		// updateComponentDimensions,
	]);

	const style = {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	};
	return (
		<Rnd
			className={`dynamic-media ${isInFullscreenMode ? 'dynamic-media-full-screen' : ''}`}
			enableResizing={{
				top: false,
				right: false,
				bottom: isInDrivingMode,
				left: false,
				topRight: false,
				bottomRight: false,
				bottomLeft: false,
				topLeft: false,
			}}
			disableDragging
			style={style}
			size={{ height: resizeContainerDimensions.height, width: '100%' }}
			position={{ x: 0, y: 0 }}
			minHeight={resizeContainerDimensions.minHeight}
			maxHeight={resizeContainerDimensions.maxHeight}
			onResize={(e, direction, ref, delta, position) => {
				const height = parseInt(ref.style.height, 10);
				updateComponentDimensions({ height });
			}}
		>
			<div className="resize-container-inner" ref={observe}>
				<div className="media-cropping-box" style={videoCroppingBoxStyle}>
					<video style={videoStyle} ref={videoRef} {...mediaComponentProps} />
				</div>
			</div>
		</Rnd>
	);
}

export default DynamicMedia;
