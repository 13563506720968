import React, { useEffect, useMemo } from 'react';
import './index.scss';
import { connect, ConnectedProps } from 'react-redux';
import { SET_DRIVING_MODE_STATUS, SET_CONFIRM_AUTO_PARK } from 'actions/types';
import { PrimaryCameraState, RobotPrimaryCamera } from 'types';
import { AppRootState } from 'reducers';
import Tooltip from '../tooltip';
import { setParameter } from 'actions/setParam';

import zoomInIcon from 'images/zoom-in.svg';
import zoomOutIcon from 'images/zoom-out.svg';
import drivingViewIcon from 'images/driving-view.svg';
import flashImage from 'images/flash.svg';
import genericAlertIcon from 'images/alert.svg';

const reduxConnector = connect(
	(state: AppRootState) => ({
		controlDataChannel: state.sessionState.controlDataChannel,
		localVoiceVolume: state.sessionState.localVoiceVolume,
		navSpeed: state.sessionState.navSpeed,
		hideNavOptionsStatus: state.sessionState.hideNavOptionsStatus,
		microphoneLevel: state.hardwareState.settings.microphone.microphoneLevel,
		drivingMode: state.sessionState.drivingMode,
		navCameraHeight: state.sessionState.navCameraHeight,
		autoParkEnabled: state.sessionState.autoParkEnabled,
		confirmAutoPark: state.sessionState.confirmAutoPark,
		isAutoParking: state.sessionState.isAutoParking,
		dockControllerStatus: state.sessionState.dockControllerStatus,
	}),
	{ setParameter }
);

type PropsFromParent = {
	togglePrimaryCamera: () => void;
	primaryCameraState: PrimaryCameraState;
	isSuperZoom1Enabled: boolean;
	onClickHangUp: () => void;
	localStream: MediaStream | null;
	navCameraRotation: number;
	hasPrimaryVideoStartedPlaying: boolean;
	isNavCameraVertical: boolean;
};

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
type ComponentProps = PropsFromRedux & PropsFromParent;

const NextCameraActionIcons: Record<RobotPrimaryCamera, string> = {
	[RobotPrimaryCamera.WIDE_CAM]: zoomInIcon,
	[RobotPrimaryCamera.ZOOM_CAM]: zoomOutIcon,
};

const RobotFeatures: React.FC<ComponentProps> = ({
	togglePrimaryCamera,
	primaryCameraState,
	hasPrimaryVideoStartedPlaying,
	controlDataChannel,
	hideNavOptionsStatus,
	drivingMode,
	navCameraHeight,
	isSuperZoom1Enabled,
	setParameter,
	isNavCameraVertical,
	autoParkEnabled,
	confirmAutoPark,
	isAutoParking,
	dockControllerStatus,
}: ComponentProps) => {
	const isSessionOpen =
		hasPrimaryVideoStartedPlaying && controlDataChannel && controlDataChannel.readyState === 'open';
	const isSwitchingPrimaryCamera = primaryCameraState.isChangingPrimaryCameraTo !== null;
	const isZoomDisabled = (!isSuperZoom1Enabled && isSwitchingPrimaryCamera) || !isSessionOpen;
	const isZoomingIn = primaryCameraState.currentPrimaryCamera !== 'wide_cam';
	const canAutoPark =
		autoParkEnabled &&
		((dockControllerStatus.stage === 'DETECTING' && dockControllerStatus.state === 'SUCCESS') ||
			(isAutoParking && ['STARTING', 'DOCKING', 'STOPPING'].includes(dockControllerStatus.stage)));

	const handleToggleDrivingMode = () => {
		setParameter('drivingMode', SET_DRIVING_MODE_STATUS, !drivingMode);
	};
	const onAutoParkClick = () => {
		setParameter('confirmAutoPark', SET_CONFIRM_AUTO_PARK, true);
	};

	const autoParkButtonPopupText = useMemo(() => {
		if (!autoParkEnabled) return 'AutoPark is not available in this Session';

		if (!canAutoPark) return 'Drive to a charging Dock to enable AutoPark';

		return 'AutoPark';
	}, [autoParkEnabled, canAutoPark]);

	return (
		<div className={hideNavOptionsStatus ? 'robotFeaturesContainer' : 'displayNone'}>
			<Tooltip
				left
				text={isSessionOpen ? (drivingMode ? 'Meeting View' : 'Driving View') : undefined}
			>
				<div className="blackCircle" onClick={handleToggleDrivingMode}>
					<img className="iconWrapper" src={drivingViewIcon} alt="" />
				</div>
			</Tooltip>
			{isSuperZoom1Enabled && (
				<Tooltip left text={!isZoomDisabled ? (isZoomingIn ? 'Zoom Out' : 'Zoom In') : undefined}>
					<div
						className={`blackCircle ${(isZoomDisabled || !isZoomingIn) && 'disabled'}`}
						onClick={isZoomDisabled || !isSessionOpen ? undefined : togglePrimaryCamera}
					>
						<img
							className="iconWrapper"
							src={NextCameraActionIcons[primaryCameraState.currentPrimaryCamera]}
							alt=""
						/>
					</div>
				</Tooltip>
			)}
			<Tooltip left showOnChange={canAutoPark} text={autoParkButtonPopupText}>
				<div
					className={`${
						isAutoParking
							? 'greenCircle'
							: `${confirmAutoPark ? 'selectedBlackCircle' : 'blackCircle'}`
					} ${!canAutoPark && 'disabled'}`}
					onClick={onAutoParkClick}
				>
					<img className="iconWrapper" src={flashImage} alt="" />
					{dockControllerStatus.stage === 'DOCKING' && dockControllerStatus.state === 'FAILED' ? (
						<img className="badge" src={genericAlertIcon} alt="" />
					) : null}
				</div>
			</Tooltip>
		</div>
	);
};

export default reduxConnector(RobotFeatures);
