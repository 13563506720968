/**
 * Default send encoding for transceiver init
 */
export const DEFAULT_TRANSCEIVER_SEND_ENCODINGS: RTCRtpEncodingParameters = {
	maxBitrate: 2 * 10 ** 6, // in bits per second
	priority: 'high',
};

/**
 * Ice servers refresh interval
 */
export const REFRESH_ICE_SERVERS_INTERVAL: number = 3 * 60 * 1000;
