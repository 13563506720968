import { KinesisVideo, KinesisVideoSignalingChannels } from 'aws-sdk';
import {
	ClientConfiguration,
	DescribeSignalingChannelOutput,
	GetSignalingChannelEndpointOutput,
	ResourceARN,
	ResourceEndpoint,
	ResourceEndpointListItem,
} from 'aws-sdk/clients/kinesisvideo';
import {
	GetIceServerConfigRequest,
	GetIceServerConfigResponse,
} from 'aws-sdk/clients/kinesisvideosignalingchannels';
import { SignalingClient as AWSSignalingClient, Role } from 'amazon-kinesis-video-streams-webrtc';
import {
	Credentials,
	SignalingClientConfig,
} from 'amazon-kinesis-video-streams-webrtc/lib/SignalingClient';
import { ISignalingClient } from './ISignalingClient';
import { WebRTCSessionConfiguration } from '../types';
import { IceTransportPolicy } from 'GoBeWebRTC/types';

/** Events that may be sent and/or received by the client */
enum SignalingEvent {
	Open = 'open',
	SDPOffer = 'sdpOffer',
	SDPAnswer = 'sdpAnswer',
	IceCandidate = 'iceCandidate',
	Close = 'close',
	Error = 'error',
}

/** AWS Kinesis signaling client */
export default class KVSSignaling implements ISignalingClient {
	iceServers: RTCIceServer[];
	isClosed: boolean = true;

	// Listeners
	onOpen: () => void;
	onSDPOffer: ((offer: RTCSessionDescription, remoteClientId: string) => void) | null;
	onSDPAnswer: ((answer: RTCSessionDescription) => void) | null;
	onIceCandidate: ((candidate: RTCIceCandidate) => void) | null;
	onClose: () => void;
	onError: (error: any) => void;

	private client: AWSSignalingClient;
	private credentials: Credentials;
	private region: string;
	private role: Role;
	private channelName: string;
	private clientId: string;
	private iceTransportPolicy: IceTransportPolicy;
	private kinesisVideoSignalingChannelsClient: KinesisVideoSignalingChannels;
	private channelARN: ResourceARN;

	constructor(readonly sessionConfiguration: WebRTCSessionConfiguration) {
		this.credentials = (({ accessKeyId, secretAccessKey }) => ({ accessKeyId, secretAccessKey }))(
			sessionConfiguration.credentials
		) as Credentials;
		this.region = sessionConfiguration.region;
		this.role = sessionConfiguration.role as Role;
		this.channelName = sessionConfiguration.id!;
		this.clientId = sessionConfiguration.clientId!;
		this.iceTransportPolicy = sessionConfiguration.credentials.iceTransportPolicy;
	}

	getIceServers = async () => {
		// Get ICE server configuration
		let getIceServerConfigResponse: GetIceServerConfigResponse =
			await this.kinesisVideoSignalingChannelsClient
				.getIceServerConfig({
					ChannelARN: this.channelARN,
				} as GetIceServerConfigRequest)
				.promise();

		if (getIceServerConfigResponse?.IceServerList?.length) {
			let server = getIceServerConfigResponse.IceServerList[0];
			server.Uris = [
				server?.Uris?.find((uri) => uri.includes('turn:') && uri.includes('transport=udp'))!,
			];
			getIceServerConfigResponse.IceServerList = [server];
		}
		this.iceServers = [];
		if (this.iceTransportPolicy !== 'relay')
			this.iceServers.push({
				urls: `stun:stun.kinesisvideo.${this.region}.amazonaws.com:443`,
			});
		getIceServerConfigResponse.IceServerList?.forEach((iceServer) =>
			this.iceServers.push({
				urls: iceServer.Uris as string | string[],
				username: iceServer.Username,
				credential: iceServer.Password,
			})
		);
	};

	init = async () => {
		if (!this.isClosed) {
			console.warn('Cannot call signalingClient.open(), already opened');
			return;
		}

		const clientConfiguration: ClientConfiguration = {
			region: this.region,
			...this.credentials,
			correctClockSkew: true,
		} as ClientConfiguration;

		// Create KVS client
		const kinesisVideoClient = new KinesisVideo(clientConfiguration);

		// Get signaling channel ARN
		const describeSignalingChannelResponse: DescribeSignalingChannelOutput =
			await kinesisVideoClient
				.describeSignalingChannel({
					ChannelName: this.channelName,
				})
				.promise();
		this.channelARN = describeSignalingChannelResponse.ChannelInfo?.ChannelARN as ResourceARN;

		// Get signaling channel endpoints
		let getSignalingChannelEndpointResponse: GetSignalingChannelEndpointOutput =
			await kinesisVideoClient
				.getSignalingChannelEndpoint({
					ChannelARN: this.channelARN,
					SingleMasterChannelEndpointConfiguration: {
						Protocols: ['WSS', 'HTTPS'],
						Role: this.role,
					},
				})
				.promise();
		const endpointsByProtocol: { [id: string]: ResourceEndpoint } | undefined =
			getSignalingChannelEndpointResponse.ResourceEndpointList?.reduce(
				(endpoints: { [id: string]: ResourceEndpoint }, endpoint: ResourceEndpointListItem) => {
					endpoints[endpoint.Protocol as string] = endpoint.ResourceEndpoint as ResourceEndpoint;
					return endpoints;
				},
				{}
			);

		this.kinesisVideoSignalingChannelsClient = new KinesisVideoSignalingChannels({
			...clientConfiguration,
			endpoint: endpointsByProtocol?.HTTPS,
		});

		await this.getIceServers();

		console.log('ICE SERVERS', this.iceServers);

		// Create Signaling Client
		this.client = new AWSSignalingClient({
			channelARN: this.channelARN,
			channelEndpoint: endpointsByProtocol?.WSS,
			clientId: 'pilot',
			role: this.role,
			region: this.region,
			credentials: this.credentials,
			systemClockOffset: kinesisVideoClient.config.systemClockOffset,
		} as SignalingClientConfig);

		// Set event listeners
		Object.entries(SignalingEvent).forEach(([key, value]) => {
			this.client.on(value, this[`on${key}` as keyof ((...args: any[]) => void)]);
		});
		this.open();
	};

	open = () => {
		this.client.open();
	};

	sendSDPOffer = (sdpOffer: RTCSessionDescription, recipientClientId?: string | undefined) =>
		this.client.sendSdpOffer(sdpOffer, recipientClientId);

	sendSDPAnswer = (sdpOffer: RTCSessionDescription, recipientClientId?: string | undefined) =>
		this.client.sendSdpAnswer(sdpOffer, recipientClientId);

	sendICECandidate = (iceCandidate: RTCIceCandidate, recipientClientId?: string | undefined) =>
		this.client.sendIceCandidate(iceCandidate, recipientClientId);

	close = () => {
		if (this.isClosed) {
			return;
		}

		this.isClosed = true;
		this.client.close();
	};
}
