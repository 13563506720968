import React, { useRef, useState, useEffect, useMemo } from 'react';
import './index.scss';
import { connect } from 'react-redux';
import { setParameter } from 'actions/setParam';
import { ConnectedProps } from 'react-redux';
import { AppRootState } from 'reducers';
import Joystick from '../../navigation/joystick';
import { NavController } from '../../navigation/useNavController';
import { Size } from 'types';
import _ from 'lodash';
import { VIDEO_SEPARATOR_HEIGHT } from '../remoteVideo';
import { SET_IS_AUTO_PARKING, SET_CONFIRM_AUTO_PARK } from '../../../../actions/types';
import { ReactComponent as CloseIcon } from 'images/close.svg';
import { SessionState } from 'GoBeWebRTC/types';
import { sessionSoftRetryingFilter } from 'utils/styling';

const reduxConnector = connect(
	(state: AppRootState) => ({
		navCameraHeight: state.sessionState.navCameraHeight,
		confirmAutoPark: state.sessionState.confirmAutoPark,
		dockControllerStatus: state.sessionState.dockControllerStatus,
	}),
	{ setParameter }
);

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
type PropsFromParent = {
	mediaStream: MediaStream;
	navController: Pick<NavController, 'onNavCommand'>;
	handleJoystickEnabled: Function;
	isJoystickMounted: boolean;
	isDrivingAllowed: boolean;
	isDrivingImpaired: boolean;
	navCameraRotation: number;
	sessionState: SessionState;
};
type ComponentProps = PropsFromRedux & PropsFromParent;

const NAV_CAM_ASPECT_RATIO = 0.75; // width/height -> width is shorter

const NavigationVideo: React.FC<ComponentProps> = ({
	mediaStream,
	setParameter,
	navCameraHeight,
	navController,
	handleJoystickEnabled,
	isJoystickMounted,
	isDrivingAllowed,
	isDrivingImpaired,
	navCameraRotation,
	sessionState,
	confirmAutoPark,
	dockControllerStatus,
}) => {
	const videoRef = useRef<HTMLVideoElement | null>(null);
	const [isAutoParking, setIsAutoParking] = useState(false);
	useEffect(() => {
		setIsVideoLoading(true);
		videoRef.current!.srcObject = mediaStream;
	}, [mediaStream]);

	const [isVideoLoading, setIsVideoLoading] = useState(true);

	const onCanPlay = () => {
		setIsVideoLoading(false);
	};

	const isSessionPaused = sessionState === 'Paused';
	const isSessionSoftRetrying = sessionState === 'SoftRetrying';
	useEffect(() => {
		if (isVideoLoading) return;

		if (isSessionPaused) {
			videoRef.current?.pause();
		} else {
			videoRef.current
				?.play()
				.catch((error) => console.error('Unable to play NavigationVideo', error));
		}
	}, [isSessionPaused, isVideoLoading]);

	const [isJoystickEnabled, setIsJoystickEnabled] = useState<boolean>(false);
	const [isJoystickHovered, setIsJoystickHovered] = useState<boolean>(false);

	const isVerticallyOriented = useMemo(() => {
		return Math.abs(navCameraRotation) % 180 === 90;
	}, [navCameraRotation]);

	const [windowDimensions, setWindowDimensions] = React.useState<Size>({
		height: window.innerHeight,
		width: window.innerWidth,
	});
	useEffect(() => {
		const handleResize = _.throttle(
			() => {
				setWindowDimensions({
					height: window.innerHeight,
					width: window.innerWidth,
				});
			},
			200,
			{ leading: true, trailing: true }
		);

		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const containerDimensions = useMemo(() => {
		const height = navCameraHeight - VIDEO_SEPARATOR_HEIGHT;
		const width = isVerticallyOriented
			? height * NAV_CAM_ASPECT_RATIO
			: height / NAV_CAM_ASPECT_RATIO;

		return { size: { width, height } };
	}, [isVerticallyOriented, navCameraHeight]);

	const componentStyle = useMemo((): React.CSSProperties => {
		return {
			width: containerDimensions.size.width,
			height: containerDimensions.size.height,
			left: (windowDimensions.width - containerDimensions.size.width) / 2,
		};
	}, [containerDimensions.size.height, containerDimensions.size.width, windowDimensions.width]);

	const videoStyle = useMemo((): React.CSSProperties => {
		let width = containerDimensions.size.width;
		let height = containerDimensions.size.height;

		if (isVerticallyOriented) {
			[width, height] = [height, width];
		}

		let top = (containerDimensions.size.height - height) / 2;
		let left = (containerDimensions.size.width - width) / 2;

		// if(isVerticallyOriented) {

		// }

		return {
			width,
			height,
			top,
			left,
			transform: `rotate(${navCameraRotation}deg)`,
			transformOrigin: 'center',
			backgroundColor: isVideoLoading ? 'black' : 'unset',
			...sessionSoftRetryingFilter(isSessionSoftRetrying),
		};
	}, [
		isVerticallyOriented,
		navCameraRotation,
		containerDimensions.size.height,
		containerDimensions.size.width,
		isVideoLoading,
		isSessionSoftRetrying,
	]);

	const renderLoadingIndicator = () => {
		const isSessionRetrying = sessionState === 'Retrying';
		const isVideoLoadingIndicatorVisible = isVideoLoading || isSessionRetrying;
		return (
			isVideoLoadingIndicatorVisible && (
				<div className="loading-indicator-container">
					<div className="loading-indicator" />
				</div>
			)
		);
	};
	useEffect(() => {
		if (
			isAutoParking &&
			dockControllerStatus.stage !== 'STARTING' &&
			dockControllerStatus.stage !== 'DOCKING'
		) {
			let evt = new KeyboardEvent('keyup', { key: 'p', keyCode: 80 });
			document.dispatchEvent(evt);
			setIsAutoParking(false);
			setParameter('isAutoParking', SET_IS_AUTO_PARKING, false);
			setParameter('confirmAutoPark', SET_CONFIRM_AUTO_PARK, false);
		}
	}, [dockControllerStatus]);
	const onAutoParkCloseClick = () => {
		let evt = new KeyboardEvent('keyup', { key: 'p', keyCode: 80 });
		document.dispatchEvent(evt);
		setParameter('confirmAutoPark', SET_CONFIRM_AUTO_PARK, false);
		setIsAutoParking(false);
		setParameter('isAutoParking', SET_IS_AUTO_PARKING, false);
	};

	const onParkclick = () => {
		document.dispatchEvent(new KeyboardEvent('keyup', { key: 'P', keyCode: 80 }));
		let evt = new KeyboardEvent('keydown', { key: 'P', keyCode: 80 });
		document.dispatchEvent(evt);
		setIsAutoParking(true);
		setParameter('isAutoParking', SET_IS_AUTO_PARKING, true);
	};

	const renderOverlayButtons = () => {
		return (
			<div className={`overlay-buttons ${!isAutoParking ? 'overlay-buttons-background ' : ''}`}>
				<div
					className={!isAutoParking ? 'autoParkButtonContainer' : 'hidden'}
					onClick={onParkclick}
				>
					Park
				</div>

				<div className="circleTextContainer" onClick={onAutoParkCloseClick}>
					<div
						className={`${!isAutoParking ? 'greyCircle' : 'autoParkClose'}  
						}`}
					>
						<CloseIcon className="iconWrapper" />
					</div>
				</div>
			</div>
		);
	};
	const handleHovered = (isHovered: boolean) => {
		setIsJoystickHovered(isHovered);
		handleJoystickEnabled(isHovered || isJoystickEnabled);
	};
	const handleEnabled = (isEnabled: boolean) => {
		setIsJoystickEnabled(isEnabled);
		handleJoystickEnabled(isEnabled || isJoystickHovered);
	};

	return (
		<div style={componentStyle} className="navigation-video">
			<video ref={videoRef} onCanPlay={onCanPlay} playsInline loop muted style={videoStyle} />
			{renderLoadingIndicator()}
			{confirmAutoPark
				? renderOverlayButtons()
				: isJoystickMounted &&
				  !isVideoLoading && (
						<Joystick
							className="joystick-container"
							size={containerDimensions.size}
							handleHovered={handleHovered}
							handleEnabled={handleEnabled}
							navController={navController}
						/>
				  )}
		</div>
	);
};

export default reduxConnector(NavigationVideo);
