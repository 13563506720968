import React, { useEffect, useState, useCallback, useLayoutEffect, useMemo } from 'react';
import LocalVideo, { LOCAL_MEDIA_CONSTRAINTS, useLocalMedia } from './videos/localVideo';
import RemotePrimaryCamVideo from './videos/remoteVideo';
import './index.scss';
import { connect } from 'react-redux';
import { setParameter } from 'actions/setParam';
import {
	SET_AUTO_PARK_ENABLED,
	SET_DATA_CHANNEL,
	SET_FULL_SCREEN_STATUS,
	SET_NAV_INPUT_ENABLED,
	SET_ROBOT_INFO,
	SET_ROBOT_STATUS,
} from 'actions/types';

import PauseOrEndSessionOverlay from './overlays/sessionEndPause';
import { ConnectedProps } from 'react-redux';
import { AppRootState } from 'reducers';
import RobotUnavailableOverlay from './overlays/failedInitPeerConnection';
import SessionNetworkFailureOverlay from './overlays/failedSessionPeerConnection';
import useCallerPeerConnection from './peerConnection/useCallerPeerConnection';
import {
	PeerConnectionEndReasonCode,
	SessionState,
} from './peerConnection/useCallerPeerConnection/peerConnection';
import ImpairedDrivingIndicator from './indicators/drivingImpairment';
import useNavController from './navigation/useNavController';
import KeyboardNavInput from './navigation/keyboard';
import MediaAccessErrorOverlay from './overlays/mediaDevicesAccessDenied';
import AutoDockingInput from './navigation/autoDocking';
import NavViewWithSessionOptions from './navigation/view';
import { IActiveNavInput, RobotPrimaryCamera, RtpReceiverID } from 'types';
import ActiveNavigationInputIndicator from './indicators/activeNavigationInput';
import useSessionOverlay from './overlays/useSessionOverlay';
import RobotName from 'components/robotName';
import { useSearchParams } from 'react-router-dom';
import SessionID from 'components/sessionID';
import RetryingSessionOverlay from './overlays/retryingSession';
import { WebRTCSessionConfiguration } from 'webRTC/types';
import useGoBeWebRTC from 'GoBeWebRTC';
import {
	DataChannels,
	GobeWebRTCSessionConfiguration,
	LocalSessionInfo,
	PilotPrimaryCamera,
	RobotNavCamera,
} from 'GoBeWebRTC/types';

const SESSION_NETWORK_FAILURE_TIMEOUT = 60 * 1000;
const DEFAULT_ZOOM_CAM_CROPPING = { top: 0, bottom: 0, left: 0, right: 0 };

const reduxConnector = connect(
	(state: AppRootState) => ({
		navSpeed: state.sessionState.navSpeed,
		localVoiceVolume: state.sessionState.localVoiceVolume,
		autoParkEnabled: state.sessionState.autoParkEnabled,
		dockControllerStatus: state.sessionState.dockControllerStatus,
	}),
	{ setParameter }
);

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;

const Session: React.FC<PropsFromRedux> = ({
	navSpeed,
	setParameter,
	localVoiceVolume,
	autoParkEnabled,
	dockControllerStatus,
}) => {
	const [searchParams] = useSearchParams();
	const [isKeyboardnav, setIskKeyboardNav] = useState(true);
	const sessionInfo = useMemo<LocalSessionInfo>(() => {
		const decodedSessionInfo = decodeURIComponent(atob(searchParams.get('sessionInfo') as string));
		return JSON.parse(decodedSessionInfo);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const gobeWebRTCSessionConfiguration: GobeWebRTCSessionConfiguration = {
		webRTCSessionConfiguration: {
			id: sessionInfo.awsClientConfiguration.id,
			pilotId: sessionInfo.pilotId,
			clientId: sessionInfo.robot.id,
			role: 'VIEWER',
			region: sessionInfo.awsClientConfiguration.region,
			credentials: sessionInfo.awsClientConfiguration,
			iceTransportPolicy: sessionInfo.awsClientConfiguration.iceTransportPolicy,
			preferredDevices: sessionInfo.devices,
		} as WebRTCSessionConfiguration,
		robotStatus: sessionInfo.robotStatus!,
	};

	const {
		pilotMedia,
		robotMedia,
		dataChannels,
		robotStatus,
		sessionState,
		primaryCameraState,
		toggleZoom,
		togglePause,
		reportWebRTCEvent,
		start,
		end,
	} = useGoBeWebRTC(gobeWebRTCSessionConfiguration);

	const closeSession = useCallback(() => {
		end();
	}, [end]);

	useEffect(() => {
		setParameter(
			'autoParkEnabled',
			SET_AUTO_PARK_ENABLED,
			'autoParkEnabled' in sessionInfo.capabilities
				? sessionInfo.capabilities.autoParkEnabled
				: true
		);
		setParameter('robot', SET_ROBOT_INFO, sessionInfo.robot);
		document.title = `GoBe - ${sessionInfo.robot.name}`;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setParameter('robotStatus', SET_ROBOT_STATUS, robotStatus);
	}, [robotStatus, setParameter]);

	useEffect(() => {
		if (dataChannels[DataChannels.CONTROL_DATACHANNEL]) {
			dataChannels[DataChannels.CONTROL_DATACHANNEL].onopen = () => {
				setParameter(
					'controlDataChannel',
					SET_DATA_CHANNEL,
					dataChannels[DataChannels.CONTROL_DATACHANNEL]
				);
				dataChannels[DataChannels.CONTROL_DATACHANNEL].send(`VOL ${localVoiceVolume}`);
			};
		}
	}, [dataChannels[DataChannels.CONTROL_DATACHANNEL]]);

	// Starting : Navigation Input management logic
	const [navInputEnabled, setNavInputEnabled] = useState<boolean>(true);
	const [currNavInput, setCurrNavInput] = useState<IActiveNavInput | null>(null);
	useMemo(() => {
		setParameter('navInputEnabled', SET_NAV_INPUT_ENABLED, navInputEnabled);
		return navInputEnabled;
	}, [navInputEnabled]);
	const buildOnNavInputFocusChanged = (
		forNavInput: IActiveNavInput | null,
		setState: (callback: (curr: IActiveNavInput | null) => IActiveNavInput | null) => void
	) => {
		return (isNavInputFocused: boolean) => {
			setState((currState) => {
				if (isNavInputFocused && navInputEnabled) return forNavInput;
				else {
					// If the nav-input identified by `forNavInput` is no longer focused,
					// 	 then we clear/reset state only if it was the one previously focused
					if (currState === forNavInput) return null;
					return currState;
				}
			});
		};
	};
	const onKeyboardNavInputFocusChanged = buildOnNavInputFocusChanged('keyboard', setCurrNavInput);
	const onJoystickActivationChanged = buildOnNavInputFocusChanged('joystick', setCurrNavInput);
	const onAutoDockingActiveChanged = buildOnNavInputFocusChanged('auto-docking', setCurrNavInput);

	// End : Navigation Input management logic

	const [hasPrimaryVideoStartedPlaying, setHasPrimaryVideoStartedPlaying] = useState(false);

	const { currentOverlay, showOverlay, hideOverlay } = useSessionOverlay();

	const onPeerConnectionStarted = useCallback(() => {
		// TODO: Add some logic for this...
		console.debug('session.Component PeerConnection started');
	}, []);

	const onPeerConnectionEnded = useCallback(
		(reason: PeerConnectionEndReasonCode) => {
			function assertUnreachable(x: never): never {
				throw new Error(`Unhandled reason-code: ${x} in onPeerConnectionEnded()`);
			}

			// todo: Show a different overlays for the retry failure cases

			switch (reason) {
				case 'LOCAL_HANGUP':
				case 'PAUSED_STATE_TIMED_OUT':
					closeSession();
					return;
				// todo: // show an error overlay instead of network failure, when the remote peer hangs up
				case 'PEER_HANGUP': // yeah, our peer is a robot! - this might be an error
				case 'FAILED_STATE_TIMED_OUT':
				case 'RETRY_FAILED':
				case 'RETRY_TIMEOUT':
				case 'ERROR':
					showOverlay('SessionNetworkFailure');
					return;
				case 'CLEANUP':
					return;
				default:
					// this is a type-safe way to ensure that all cases
					//  	of the PeerConnectionEndReasonCode are handled in this switch
					return assertUnreachable(reason);
			}
		},
		[closeSession, showOverlay]
	);

	// const signalingClient = useSignalingClient(sessionInfo);

	// // setup the controls for the peer connection
	// const {
	// 	endPeerConnection,
	// 	startPeerConnection,
	// 	pausePeerConnection,
	// 	unpausePeerConnection,
	// 	togglePrimaryCamera,
	// 	primaryCameraState,
	// 	primaryMediaStream,
	// 	primaryRTPTransceiver,
	// 	navMediaStream,
	// 	navRTPTransceiver,
	// 	capabilities,
	// 	robotStatus,
	// 	sessionState,
	// } = useCallerPeerConnection({
	// 	signalingClient,
	// 	onDataChannel,
	// 	onStarted: onPeerConnectionStarted,
	// 	onEnded: onPeerConnectionEnded,
	// });

	const primaryCamerasConfig = useMemo((): React.ComponentProps<
		typeof RemotePrimaryCamVideo
	>['cameraConfigs'] => {
		return {
			[RobotPrimaryCamera.WIDE_CAM]: {
				rotationDegrees: sessionInfo.capabilities.wide_camera_rotation,
				crop: sessionInfo.capabilities.wide_camera_crop,
			},
			[RobotPrimaryCamera.ZOOM_CAM]: {
				rotationDegrees: sessionInfo.capabilities.zoom_camera_rotation,
				crop: DEFAULT_ZOOM_CAM_CROPPING,
			},
		};
	}, [sessionInfo.capabilities]);

	const [isSessionInitializing, setIsSessionInitializing] = useState(true);
	const isSessionStarting =
		sessionState === 'NotInitialized' ||
		(sessionState === 'InProgress' && !hasPrimaryVideoStartedPlaying);
	const isSessionRetrying = sessionState === 'Retrying';
	const isSessionPaused = sessionState === 'Paused';

	useEffect(() => {
		if (isSessionRetrying || isSessionStarting) {
			showOverlay('SessionRetrying');
		}
	}, [hideOverlay, isSessionRetrying, isSessionStarting, isSessionPaused, showOverlay]);
	useEffect(() => {
		if (pilotMedia[PilotPrimaryCamera.LOCAL]?.error!) {
			showOverlay('LocalMediaError');
		}
	}, [pilotMedia]);

	/** True if the user can see at least a frame of the video,
	 * and the video is not obscured by any fullscreen-overlays */
	const isVideoVisible = hasPrimaryVideoStartedPlaying && currentOverlay === null;

	const videoRtpReceivers = useMemo(() => {
		return {
			primaryCam: robotMedia[RobotPrimaryCamera.WIDE_CAM]?.transceiver?.receiver,
			// TODO: change back to nav cam receiver
			navCam: robotMedia[RobotPrimaryCamera.WIDE_CAM]?.transceiver?.receiver,
		} as Partial<Record<RtpReceiverID, RTCRtpReceiver>>;
	}, [robotMedia[RobotNavCamera.NAV_CAM], robotMedia[RobotPrimaryCamera.WIDE_CAM]]);
	const { navController, isNavigationInProgress, isDrivingImpaired, penalty } = useNavController({
		speed: navSpeed,
		isPeerConnectionPaused: isSessionPaused,
		rtpReceivers: videoRtpReceivers,
		datachannel: dataChannels[DataChannels.NAV_DATACHANNEL],
		isVideoVisible,
	});

	const onClickPauseSession = () => {
		togglePause();
	};

	const onClickUnpauseSession = () => {
		togglePause();
		hideOverlay();
	};

	// Starting : Fullscreen status management logic
	useEffect(() => {
		// add the fullscreen event handler
		const fullScreenChangeHandler = () => {
			if (document.fullscreenElement) {
				setParameter('fullScreenStatus', SET_FULL_SCREEN_STATUS, true);
			} else {
				setParameter('fullScreenStatus', SET_FULL_SCREEN_STATUS, false);
			}
		};
		document.addEventListener('fullscreenchange', fullScreenChangeHandler);

		return () => {
			document.removeEventListener('fullscreenchange', fullScreenChangeHandler);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	// End : Fullscreen status management logic

	useLayoutEffect(() => {
		if (hasPrimaryVideoStartedPlaying) return;
		const primaryCamVideoTimeoutID = setTimeout(
			() => showOverlay('SessionNetworkFailure'),
			SESSION_NETWORK_FAILURE_TIMEOUT
		);
		return () => clearTimeout(primaryCamVideoTimeoutID);
	}, [hasPrimaryVideoStartedPlaying, showOverlay]);
	useLayoutEffect(() => {
		if (sessionState !== 'Retrying') return;
		const retryFailedTimeoutID = setTimeout(
			() => showOverlay('SessionNetworkFailure'),
			SESSION_NETWORK_FAILURE_TIMEOUT
		);
		return () => clearTimeout(retryFailedTimeoutID);
	}, [sessionState, showOverlay]);
	const onPrimaryVideoPlaybackToggle = (value: boolean) => {
		setHasPrimaryVideoStartedPlaying(value);
		if (value && !isSessionPaused) {
			setIsSessionInitializing(false);
			hideOverlay();
		}
	};

	const isKeyboardNavInputEnabled =
		currentOverlay === null &&
		(currNavInput === 'keyboard' || currNavInput == null) &&
		primaryCameraState.currentPrimaryCamera === 'wide_cam' &&
		navInputEnabled;

	const isJoystickControlSupportedByRobot = true;

	const renderNavViewWithSessionOptions = (args: { isJoystickMounted: boolean }) => {
		return (
			<NavViewWithSessionOptions
				// * SessionOptions props
				onClickHangUp={() => showOverlay('EndOrPauseSessionConfirmation')}
				togglePrimaryCamera={toggleZoom}
				primaryCameraState={primaryCameraState}
				isSuperZoom1Enabled={sessionInfo.capabilities.super_zoom_1}
				localStream={pilotMedia[PilotPrimaryCamera.LOCAL]?.media?.stream!}
				hasPrimaryVideoStartedPlaying={robotMedia[RobotPrimaryCamera.WIDE_CAM]?.streams[0]?.active}
				// * NavigationVideo props
				mediaStream={robotMedia[RobotNavCamera.NAV_CAM]?.streams[0]}
				navController={navController}
				handleJoystickEnabled={onJoystickActivationChanged}
				isJoystickMounted={args.isJoystickMounted}
				isDrivingAllowed={currNavInput === 'joystick' || currNavInput === 'keyboard'}
				isDrivingImpaired={isDrivingImpaired}
				penalty={penalty}
				// * extra props
				navCameraRotation={sessionInfo.capabilities.nav_camera_rotation}
				isNavigating={isNavigationInProgress}
				sessionState={sessionState}
			/>
		);
	};

	useEffect(() => {
		start();
	}, []);

	return (
		<div
			className="Session"
			id="Session"
			data-session-id={sessionInfo.uuid}
			// FIXME: We must remove any sensitive data from sessionInfo, before adding it as a data-attribute
			// data-session-info={signalingClient.sessionInfo}
		>
			<PauseOrEndSessionOverlay
				isVisible={currentOverlay === 'EndOrPauseSessionConfirmation'}
				isSessionPaused={isSessionPaused}
				onClickResumeSession={onClickUnpauseSession}
				onClickEndSession={() => end('LOCAL_HANGUP')}
				onClickPauseSession={onClickPauseSession}
				onClickCancel={hideOverlay}
			/>

			<RetryingSessionOverlay
				isVisible={currentOverlay === 'SessionRetrying'}
				isSessionStarting={isSessionStarting}
				isSessionInitializing={isSessionInitializing}
				robot={sessionInfo.robot}
				onClickEndSession={() => end('LOCAL_HANGUP')}
			/>

			<RobotUnavailableOverlay
				isVisible={currentOverlay === 'UnavailableRobot' || currentOverlay === 'NoRemoteVideo'}
				closeSession={closeSession}
				onClickTryAgain={closeSession} // TODO: Implement this, and allow user to call robot again
			/>
			<SessionNetworkFailureOverlay
				isVisible={currentOverlay === 'SessionNetworkFailure'}
				closeSession={closeSession}
				robotName={sessionInfo.robot.name}
			/>

			<MediaAccessErrorOverlay
				isVisible={currentOverlay === 'LocalMediaError'}
				onEndSession={() => end('LOCAL_HANGUP')}
				robotName={sessionInfo.robot.name}
				error={pilotMedia[PilotPrimaryCamera.LOCAL]?.error!}
			/>

			<KeyboardNavInput
				navController={navController}
				disabled={!isKeyboardNavInputEnabled}
				onFocusChanged={onKeyboardNavInputFocusChanged}
				currNavInput={currNavInput}
			>
				<div className="sessionInfoContainer">
					<SessionID id={sessionInfo.uuid} />
					<div className="sessionInfoContainerLower">
						<ActiveNavigationInputIndicator
							activeNavInput={currNavInput}
							isDrivingImpaired={isDrivingImpaired}
							dockControllerStatus={dockControllerStatus}
							reconnecting={sessionState === 'SoftRetrying'}
						/>
						<ImpairedDrivingIndicator
							isKeyboardnav={isKeyboardnav}
							isDrivingImpaired={isDrivingImpaired}
							penalty={penalty}
							dockControllerStatus={dockControllerStatus}
						/>
						{autoParkEnabled ? (
							<AutoDockingInput
								onActivenessChanged={onAutoDockingActiveChanged}
								navDataChannel={dataChannels[DataChannels.NAV_DATACHANNEL]}
								isPeerConnectionPaused={isSessionPaused}
								isVideoVisible={isVideoVisible}
								reportWebRTCEvent={reportWebRTCEvent}
							/>
						) : null}
						<RobotName name={sessionInfo.robot.name} />
					</div>
				</div>
				<LocalVideo
					robotStatus={robotStatus ?? sessionInfo.robotStatus!}
					startWideCameraStats={() => undefined}
					stopWideCameraStats={() => undefined}
					wideCameraStats=""
					isGreyedOut={false}
					isPaused={false}
					shouldShowLoadingIndicator={
						!hasPrimaryVideoStartedPlaying ||
						!pilotMedia[PilotPrimaryCamera.LOCAL]?.media?.stream?.active ||
						isSessionRetrying ||
						isSessionPaused
					}
					media={pilotMedia[PilotPrimaryCamera.LOCAL]?.media}
					sessionState={sessionState}
					setNavInputEnabled={setNavInputEnabled}
				/>
				<RemotePrimaryCamVideo
					primaryCameraState={primaryCameraState}
					sessionState={sessionState}
					onPlaybackToggle={onPrimaryVideoPlaybackToggle}
					mediaStream={robotMedia[RobotPrimaryCamera.WIDE_CAM]?.streams[0]}
					cameraConfigs={primaryCamerasConfig}
					canShowLoadingIndicator
				/>

				{/* If the robot does not support joystick control, we render the nav-view within the keyboard input.
					This makes it possible to the pilot to be able to control the robot with keyboard
					even when the mouse cursor is on the nav-view. */}
				{!isJoystickControlSupportedByRobot &&
					renderNavViewWithSessionOptions({
						isJoystickMounted: false,
					})}
				{isJoystickControlSupportedByRobot &&
					renderNavViewWithSessionOptions({
						isJoystickMounted:
							primaryCameraState.currentPrimaryCamera === 'wide_cam' &&
							currNavInput !== 'auto-docking',
					})}
			</KeyboardNavInput>
		</div>
	);
};

export default React.memo(reduxConnector(Session));
