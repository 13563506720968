import React, { useEffect, useRef, useState, useMemo } from 'react';
import './index.scss';
import { connect, ConnectedProps } from 'react-redux';
import Slider from '../slider';

import { SET_LOCAL_VOICE_VOLUME, SET_NAV_SPEED } from 'actions/types';
import { PrimaryCameraState } from 'types';
import { AppRootState } from 'reducers';
import Tooltip from '../tooltip';
import { setParameter } from 'actions/setParam';

import redMicOffIcon from 'images/mic-off-red.svg';
import micIcon from 'images/mic.svg';
import whiteSpeedIcon from 'images/white-speed.svg';
import whiteStopIcon from 'images/white-stop.svg';
import genericAlertIcon from 'images/alert.svg';
import redAlertIcon from 'images/red-alert.svg';

const reduxConnector = connect(
	(state: AppRootState) => ({
		controlDataChannel: state.sessionState.controlDataChannel,
		localVoiceVolume: state.sessionState.localVoiceVolume,
		navSpeed: state.sessionState.navSpeed,
		hideNavOptionsStatus: state.sessionState.hideNavOptionsStatus,
		microphoneLevel: state.hardwareState.settings.microphone.microphoneLevel,
		drivingMode: state.sessionState.drivingMode,
		navCameraHeight: state.sessionState.navCameraHeight,
		isAutoParking: state.sessionState.isAutoParking,
	}),
	{ setParameter }
);

type PropsFromParent = {
	togglePrimaryCamera: () => void;
	primaryCameraState: PrimaryCameraState;
	isSuperZoom1Enabled: boolean;
	onClickHangUp: () => void;
	localStream: MediaStream | null;
	hasPrimaryVideoStartedPlaying: boolean;
	isNavCameraVertical: boolean;
	isDrivingAllowed: boolean;
	isDrivingImpaired: boolean;
	penalty: number;
};

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
type ComponentProps = PropsFromRedux & PropsFromParent;

const SessionOptions: React.FC<ComponentProps> = ({
	localStream,
	primaryCameraState,
	onClickHangUp,
	hasPrimaryVideoStartedPlaying,
	controlDataChannel,
	localVoiceVolume,
	navSpeed,
	hideNavOptionsStatus,
	microphoneLevel,
	drivingMode,
	navCameraHeight,
	setParameter,
	isNavCameraVertical,
	isDrivingAllowed,
	isDrivingImpaired,
	penalty,
	isAutoParking,
}: ComponentProps) => {
	const localVoiceRef = useRef<any>(null);
	const [localVoiceValue, changeLocalVoiceValue] = useState<any>('19');
	const icon = useMemo(() => (penalty === 1 ? redAlertIcon : genericAlertIcon), [penalty]);

	const onLocalVoiceChange = (value: number) => {
		setParameter('localVoiceVolume', SET_LOCAL_VOICE_VOLUME, value);
		changeLocalVoiceValue(value);
		if (controlDataChannel && controlDataChannel.readyState === 'open') {
			controlDataChannel.send(`VOL ${value}`);
		}
	};

	useEffect(() => {
		if (microphoneLevel) {
			setParameter('localVoiceVolume', SET_LOCAL_VOICE_VOLUME, microphoneLevel);
			changeLocalVoiceValue(microphoneLevel);
		}
	}, [microphoneLevel, setParameter]);

	const onLocalVoiceIconClick = () => {
		if (
			hasPrimaryVideoStartedPlaying &&
			controlDataChannel &&
			controlDataChannel.readyState === 'open'
		) {
			if (localVoiceVolume === '0') {
				if (localVoiceValue === '0') {
					if (controlDataChannel && controlDataChannel.readyState === 'open') {
						controlDataChannel.send(`VOL 19`);
					}
					setParameter('localVoiceVolume', SET_LOCAL_VOICE_VOLUME, '19');
				} else {
					if (controlDataChannel && controlDataChannel.readyState === 'open') {
						controlDataChannel.send(`VOL ${localVoiceValue}`);
					}
					setParameter('localVoiceVolume', SET_LOCAL_VOICE_VOLUME, localVoiceValue);
				}
			} else {
				if (controlDataChannel && controlDataChannel.readyState === 'open') {
					controlDataChannel.send(`VOL 0`);
				}
				setParameter('localVoiceVolume', SET_LOCAL_VOICE_VOLUME, '0');
			}
		}
	};

	const onNavSpeedChange = (value: number) => {
		setParameter('navSpeed', SET_NAV_SPEED, value);
	};

	useEffect(() => {
		if (localStream) {
			if (parseInt(localVoiceVolume) === 0) {
				localStream.getAudioTracks()[0].enabled = false;
			} else {
				localStream.getAudioTracks()[0].enabled = true;
			}
		}
	}, [localStream, localVoiceVolume]);

	const isSessionOpen =
		hasPrimaryVideoStartedPlaying && controlDataChannel && controlDataChannel.readyState === 'open';
	const isZoomingIn = primaryCameraState.currentPrimaryCamera !== 'wide_cam';

	return (
		<div className={hideNavOptionsStatus ? 'sessionOptionsContainer' : 'displayNone'}>
			<div
				className={`roundedButton ${
					!isSessionOpen || isZoomingIn || isAutoParking
						? 'disabled'
						: 'rectangle navSpeedRectangle'
				}`}
			>
				<div>
					<img className="iconWrapper" src={whiteSpeedIcon} alt="" />
					{isDrivingImpaired ? <img className="badge" src={icon} alt="" /> : null}
				</div>
				{isSessionOpen && !isZoomingIn && !isAutoParking && (
					<>
						<div className="localVoiceText">
							{(0.6 + (2.6 * parseInt(navSpeed)) / 100).toString().slice(0, 3)}
							<span>km/h</span>
						</div>
						<div className="rectangleLocalVoice">
							<Slider
								onChange={onNavSpeedChange}
								value={navSpeed}
								icon="speed-green.svg"
								id="navVideoSpeed"
							/>
						</div>
					</>
				)}
			</div>
			<div className={`roundedButton rectangle ${!isSessionOpen && 'disabled'}`}>
				<img
					className="iconWrapper"
					onClick={onLocalVoiceIconClick}
					src={parseInt(localVoiceVolume) === 0 ? redMicOffIcon : micIcon}
					alt=""
				/>
				{isSessionOpen && (
					<>
						<div className="localVoiceText">{localVoiceVolume}%</div>
						<div className="rectangleLocalVoice" ref={localVoiceRef}>
							<Slider
								onChange={onLocalVoiceChange}
								value={localVoiceVolume}
								icon={parseInt(localVoiceVolume) === 0 ? 'mic-off-red.svg' : 'mic-off-green.svg'}
								id="localVideoVolume"
							/>
						</div>
					</>
				)}
			</div>
			<Tooltip text="Pause/End" disabled={isAutoParking}>
				<div
					className={`roundedButton ${isAutoParking ? 'disabled' : 'red'}`}
					onClick={() => {
						if (!isAutoParking) onClickHangUp();
					}}
				>
					<img className="iconWrapper" src={whiteStopIcon} alt="" />
				</div>
			</Tooltip>
		</div>
	);
};

export default reduxConnector(SessionOptions);
