import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import useAutoDockingController, { AutoDockingController } from './useAutoDockingController';
import AutoDockingStatusIndicator from '../../indicators/autoDockingStatus';
import useDockingKeyPressStateChart from './useDockingKeyPressStateChart';
import { setParameter } from 'actions/setParam';
import { SET_DOCK_CONTROLLER_STATUS } from 'actions/types';
import { connect, ConnectedProps } from 'react-redux';
import { AppRootState } from 'reducers';

type AutoDockingInputProps = {
	navDataChannel?: RTCDataChannel;
	isPeerConnectionPaused: boolean;
	isVideoVisible: boolean;
	onActivenessChanged: (isActive: boolean) => void;
	reportWebRTCEvent: (data: any) => void;
};

type AutoDockingStatus = AutoDockingController['status'];
const reduxConnector = connect(
	(state: AppRootState) => ({
		isAutoParking: state.sessionState.isAutoParking,
		navInputEnabled: state.sessionState.navInputEnabled,
	}),
	{ setParameter }
);

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
type ComponentProps = PropsFromRedux & AutoDockingInputProps;
/**
 * Handles capturing keyboard events related to docking,
 * and forwarding to AutoDockingController
 * */
function AutoDockingInput({
	navDataChannel,
	isPeerConnectionPaused,
	isVideoVisible,
	onActivenessChanged,
	setParameter,
	isAutoParking,
	navInputEnabled,
	reportWebRTCEvent,
}: ComponentProps) {
	const controller = useAutoDockingController({
		datachannel: navDataChannel,
		isPeerConnectionPaused,
		isVideoVisible,
	});

	const {
		state: keyPressState,
		onKeyDown: _onKeyDown,
		onKeyUp,
		onDockingInitiated,
		onDockingTerminated,
		onDockingError,
	} = useDockingKeyPressStateChart({
		sendSTART: () => controller.start(isAutoParking),
		sendSTOP: () => {
			controller.stop();
			if (isAutoParking) {
				reportWebRTCEvent({
					type: 'global',
					name: 'parkingStopped',
					parameters: {},
				});
			}
		},
		sendCONTINUE: controller.onDockCONTINUE,
		sendRESET: controller.reset,
	});

	const onKeyDownEvent = useCallback(
		(e: any) => {
			if (navInputEnabled) _onKeyDown(e);
		},
		[navInputEnabled, _onKeyDown]
	);

	const [status, setStatus] = useState<AutoDockingStatus>(controller?.status);

	useEffect(() => {
		const isKeyPressedDown = (
			[
				'pressedButWaiting',
				'pressedAndEngaged.withKeyDown',
				'pressedAndEngaged.withKeyUp',
			] as Array<typeof keyPressState>
		).includes(keyPressState);
		onActivenessChanged(isKeyPressedDown);
	}, [keyPressState]);

	useEffect(() => {
		setParameter('dockControllerStatus', SET_DOCK_CONTROLLER_STATUS, controller.status);
		setStatus(controller.status);
	}, [controller.status, setParameter]);

	useLayoutEffect(() => {
		const onStatusChanged = () => {
			setStatus(controller.status);

			const { stage, state } = controller.status;
			if (stage === 'DOCKING') {
				if (state === 'SUCCESS') {
					onDockingTerminated();
					reportWebRTCEvent({
						type: 'global',
						name: 'parkingSuccess',
						parameters: {
							isAutoParking,
						},
					});
				} else if (state === 'FAILED') {
					onDockingError();
					if (isAutoParking)
						reportWebRTCEvent({
							type: 'global',
							name: 'parkingFailed',
							parameters: {
								isAutoParking,
							},
						});
				}
			} else if (stage === 'STARTING' && state === 'SUCCESS') {
				onDockingInitiated();
				if (isAutoParking)
					reportWebRTCEvent({
						type: 'global',
						name: 'parkingStarted',
						parameters: {
							isAutoParking,
						},
					});
			}
		};
		controller.addEventListener('status-changed', onStatusChanged);
		return () => {
			controller.removeEventListener('status-changed', onStatusChanged);
		};
	}, [
		controller,
		onDockingInitiated,
		onDockingTerminated,
		onDockingError,
		setParameter,
		isAutoParking,
		reportWebRTCEvent,
	]);

	useLayoutEffect(() => {
		document.addEventListener('keydown', onKeyDownEvent, { capture: true });
		document.addEventListener('keyup', onKeyUp, { capture: true });
		return () => {
			document.removeEventListener('keydown', onKeyDownEvent, { capture: true });
			document.removeEventListener('keyup', onKeyUp, { capture: true });
		};
	}, [onKeyDownEvent, onKeyUp]);

	return <AutoDockingStatusIndicator status={status} />;
}

export default reduxConnector(AutoDockingInput);
