import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppRootState } from 'reducers';
import { setParameter } from 'actions/setParam';
import { openFullscreen, isInFullscreen } from 'utils/fullScreen';
import { SET_FULL_SCREEN_STATUS } from 'actions/types';
import { LocalSessionInfo } from 'GoBeWebRTC/types';

function requestMediaDevicesPermission() {
	return navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then((stream) => {
		// TODO: Stop the media tracks associated with the stream?
		stream.getTracks().forEach((track) => track.stop());
	});
}

const reduxConnector = connect((state: AppRootState) => ({}), { setParameter });

type Props = ConnectedProps<typeof reduxConnector>;

const Lobby: React.FC<Props> = ({ setParameter }) => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const localSessionInfo = searchParams.get('sessionInfo');

	useEffect(() => {
		const onFullscreenChanged = () => {
			if (document.fullscreenElement) {
				setParameter('fullScreenStatus', SET_FULL_SCREEN_STATUS, true);
			} else {
				setParameter('fullScreenStatus', SET_FULL_SCREEN_STATUS, false);
			}
		};
		document.addEventListener('fullscreenchange', onFullscreenChanged);

		return () => {
			document.removeEventListener('fullscreenchange', onFullscreenChanged);
		};
	}, [setParameter]);

	useEffect(() => {
		if (localSessionInfo) {
			openFullscreen().finally(() => {
				// Immediately remember that the we are in fullscreen mode.
				// When the component unmounts and hte fullscreenchange event handler is removed
				//  we would have already saved the fullscreen state
				setParameter('fullScreenStatus', SET_FULL_SCREEN_STATUS, isInFullscreen());

				requestMediaDevicesPermission().finally(() => {
					navigate('session/?sessionInfo=' + localSessionInfo);
					// NB: If permission request fails, the right error message will be displayed on Session page.
				});
			});
		} else {
			console.error("No 'sessionInfo' was found in url");
			// TODO: Show an error. Currently, the user will be staring at a blank black screen
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return <></>;
};

export default reduxConnector(Lobby);
